.UserDetail {
  &__back {
    color: #002060;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    display: inline-block;
    width: 71px;
  }
  &__link-module {
    height: 279px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 10px;
  }
  &__button {
    float: right;
  }

  .ant-card {
    &-body {
      height: calc(100% - 73px);
      display: flex;
      flex-direction: column;

      .ant-tabs {
        flex: 1;
        height: calc(100% - 43px);

        &-content,
        &-tabpane {
          height: 100%;
        }

        &-content-holder {
          overflow: auto;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-wrap {
      flex: 1;
    }

    .ant-collapse {
      margin-bottom: 14px;

      &-header {
        padding: 3px 3px !important;
      }

      &-expand-icon {
        .ant-collapse-arrow {
          svg {
            margin-bottom: 3px;
          }
        }
      }

      .UserDetail__panel {
        .ant-collapse {
          &-header {
            padding: 0;
          }

          &-content {
            border-top: 0;

            .ant-collapse-content-box {
              padding-top: 16px;
            }
          }
        }
      }
    }

    &-item {
      &-roles.ant-form-item {
        margin-bottom: 0;
      }
    }

    &-footer {
      // flex: 1;
      text-align: end;

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    padding: 4px 24px 4px 12px;
  }

  .ant-select-selection-overflow {
    min-height: 30px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #002060;
    font-size: 14px;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #002060;
    pointer-events: none;
  }

  .ant-tabs-tab:hover {
    color: #002060;
  }
}
