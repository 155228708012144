.AdditionalPermissionTab {
  height: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-wrap {
      flex: 1;
    }

    .ant-collapse {
      margin-bottom: 14px;

      &-header {
        padding: 3px 3px !important;
      }

      &-expand-icon {
        .ant-collapse-arrow {
          svg {
            margin-bottom: 3px;
          }
        }
      }
    }

    &-item {
      &-roles.ant-form-item {
        margin-bottom: 0;
      }
    }

    &-footer {
      text-align: end;

      .ant-form-item {
        margin-bottom: 0;
      }
    }

    &-tag {
      .ant-tag-close-icon svg {
        margin-bottom: 4px;
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    padding: 4px 24px 4px 12px;
  }

  .ant-select-selection-overflow {
    min-height: 30px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #002060;
    font-size: 14px;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #002060;
    pointer-events: none;
  }

  .ant-tabs-tab:hover {
    color: #002060;
  }
}
