$primaryColor: #002060;

.flex-space-between {
  display: flex;
  justify-content: space-between;
  // justify-content: flex-end;
}

.card-layout {
  width: 100%;
  height: auto;
  height: 810px;

  & .ant-card-head-title {
    font-size: 16px;
    color: $primaryColor;
  }
}

.card-layout-msa {
  width: 100%;
  height: 788px;
  overflow: hidden;

  & .ant-card-head-title {
    color: $primaryColor;
    font-size: 16px;
    font-weight: 17px;
  }
}

.sub-card {
  width: 100%;
  margin-bottom: 9px;
}

.sub-title-common {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
}

.label-common {
  font-size: 14px;
  font-weight: 400;
  color: #9a9ea4;
}

.release-text-info {
  color: #5daad6;
  font-weight: 400;
}

.text-info {
  color: #5daad6;
  font-weight: 400;
  font-size: 14px;
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add-modal {
  .ant-modal-footer {
    .ant-btn {
      width: 80px;
      height: 35px;
      line-height: normal;
    }
  }
}

.disable-item {
  opacity: 0.5;
  cursor: not-allowed !important;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    cursor: no-drop;
  }
}

.disable-img {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.ant-switch-checked {
  background-color: #002060;
}

td.ant-table-column-sort,
.ant-table-thead th.ant-table-column-sort {
  background: none;
}

.icon-hover-primary {
  cursor: pointer;

  &:hover:not(.disabled-icon) {
    filter: brightness(0) saturate(100%) invert(12%) sepia(34%) saturate(5459%) hue-rotate(212deg) brightness(89%)
      contrast(107%);
  }
}

.disabled-icon {
  opacity: 0.5;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    cursor: no-drop;
  }
}

.ant-typography {
  margin-bottom: 0px !important;
}

/* screen 35 inch */
@media screen and (min-width: 3400px) {
  .card-layout {
    height: 1260px;
  }

  .acceptance-article {
    height: 1260px !important;
  }

  .my-work-list {
    &__card {
      height: 1260px !important;
    }
  }

  .ai-pagetype .ant-card-body {
    height: 900px !important;
    display: flex;
    flex-direction: column;
  }

  .release-right__content,
  .userStories-right__content {
    height: 880px !important;
  }
}

.ant-popover-inner {
  max-height: 400px;
  overflow: auto;
}

.textarea {
  resize: none !important;
}

.btn-primary {
  background-color: #002060;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgb(0 0 0/7%);
  color: white;
  &:hover {
    background-color: #002060;
    color: white;
  }
}
