.forgotpassword {
  .ant-modal-body {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .modal_card {
    text-align: center;
  }
  .card_title {
    font-size: 38px;
    font-weight: bold;
  }
  .card_text {
    align-items: center;
    text-indent: 100px;
    font-size: 14px;
  }
  .input_username {
    background: #f9f9f9;
    height: 50px;
    border-radius: 12px;
  }
  .input_username::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
  .ant-input:placeholder-shown {
    background-color: #f9f9f9 !important;
  }
  .button {
    margin-top: 15px;
    background: #002060 !important;
    height: 51px;
    width: 100%;
    border: none;
    border-radius: 12px;
  }
  .textButton {
    color: #fff;
    font-size: 18px;
  }
  .link {
    text-align: center;

    .textlink {
      align-items: center;
      font-size: 18px;
    }
  }
  .img_vertify {
    padding-bottom: 29px;
  }
  .title_vertify {
    font-size: 24px;
    text-align: center;
  }
  .text_vertify {
    font-size: 18px;
    text-align: center;
  }
  .text_button {
    color: #002060;
    font-size: 18px;
    text-decoration: underline;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
}
