@use 'components/login';
@use 'components/modalpassword';
@use 'components/sidebar';
@use 'components/newpassword';
@use 'components/header';
@use 'common'as common;

@font-face {
  font-family: 'ERASBD';
  src: url('../styles/fonts/ERASBD.TTF') format('truetype');
}

@font-face {
  font-family: 'ERASMD';
  src: url('../styles/fonts/ERASMD.TTF') format('truetype');
}

@font-face {
  font-family: 'ERASDEMI';
  src: url('../styles/fonts/ERASDEMI.TTF') format('truetype');
}

@font-face {
  font-family: 'UNTRAITALIC';
  src: url('../styles/fonts/UNTRAITALIC.ttf') format('truetype');
}

html * {
  font-family: Poppins;
}

.ant-btn-primary {
  background-color: common.$primaryColor;

  &:hover {
    background-color: common.$primaryColor;
    background: common.$primaryColor;
  }

  &:focus {
    background-color: common.$primaryColor;
    background: common.$primaryColor;
  }
}

.layout-dashboard .ant-layout-header {
  margin: 0px;
  padding: 20px;
  background: #ffffff;
  border-radius: 0;
  border-bottom: 1px solid #eeeeee;
}

.layout-dashboard .ant-layout-sider.sider-primary {
  width: 250px;
  position: fixed;
  left: 0;
  z-index: 99;
  height: calc(100% - 95px);
  overflow: auto;
  margin: 0;
  padding: 10px 20px;
  // min-height: 100%;
}

.layout-dashboard .ant-layout {
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
}

.ant-message-custom-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  white-space: pre;
}

.ant-modal-title {
  color: common.$primaryColor;
}

.ant-modal-body {
  white-space: pre-wrap;
  overflow: auto;
}

.ant-tooltip-inner {
  white-space: pre-wrap;
  max-height: 400px;
  overflow: auto;
}

::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
  background-color: #f5f5f5;
  // display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}

::-webkit-scrollbar-track:hover {
  background: rgba(0, 0, 0, 0.06);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(155, 155, 155);
  border: 2px solid transparent;
}

.ant-table-cell {
  font-size: 13px;
  padding: 8px 8px;
}

.ant-table-body {
  overflow-y: auto !important;
}

input::placeholder {
  font-weight: 400;
}

.ant-tree-title {
  font-size: 13px;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: common.$primaryColor;
  border-color: common.$primaryColor;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: common.$primaryColor;
}

.ant-popover {
  z-index: 1001;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-spin {
  color: common.$primaryColor;
}

.ant-spin-dot-item {
  background-color: common.$primaryColor;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  height: 30px;
  display: flex;
  align-items: center;
}

.deatil-comment {
  resize: none;
  margin-bottom: 10px;
  margin-top: 5px;
}

.react-multi-carousel-dot--active {
  button {
    background: common.$primaryColor !important;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: common.$primaryColor;

  &::after {
    background-color: common.$primaryColor;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: common.$primaryColor;
  border-color: common.$primaryColor;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  &::after {
    background-color: common.$primaryColor;
  }
}

.react-multiple-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.2) !important;

  &--left {
    left: -13px !important;
  }

  &--right {
    right: -13px !important;
  }

  &:hover {
    background-color: common.$primaryColor !important;
  }
}

.mini-sidebar {
  .ant-menu-item {
    .label {
      display: none;
    }

    .menu-item-header {
      display: none;
    }
  }

  .menu-full-item {
    display: none;
  }

  .ant-layout-has-sider {
    margin-left: 110px;
  }

  .iframe-enterprise {
    width: 100%;
    height: calc(100vh - 110px);
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-btn-primary {
  background-color: #002060;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  color: white;
  border-color: #002060;

  &:hover {
    background-color: #002060;
    color: white;
    border-color: #002060;
  }

  &:disabled {
    border-color: #f0f0f0 !important;
    cursor: not-allowed;
  }

  &:disabled:hover {
    border-color: #f0f0f0 !important;
  }
}

.mt-10 {
  margin-top: 10px;
}

.btn-button {
  width: 120px;
}

.popup-confirm-delete {

  .ant-modal-content {
    border-radius: 20px;
  }
}

.popup-confirm-licence {
  .ant-modal-close {
    display: none;
  }
}