.sidebar__menu__main {
  // height: 100%;
  padding-right: 5px;
}

.blur-when-edit-page-layout {
  z-index: 101;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 52%;
}

.sidebar {
  .ant-menu {
    &-title {
      &-content {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

          &.sidebar-icon__qmfactory {
            object-fit: cover;
          }
        }
      }
    }

    &-submenu {
      &.onlyText {
        .ant-menu-submenu-title {
          padding-left: 20px !important;
        }
      }

      &-title {
        padding-left: 10px !important;
        padding-right: 28px !important;
        border-radius: 4px;
        border-bottom: 1px solid #ffffff;
        border-right: 1px solid #ffffff;

        .submenu-title {
          .ant-typography {
            color: #ffffff;
            font-weight: 700;
            font-family: 'ERASDEMI', sans-serif;
            font-size: 16px;
          }
        }

        .ant-menu-submenu-arrow {
          right: 5px;

          &::before,
          &::after {
            background-color: #ffffff;
          }
        }

        .ant-menu-title-content {
          color: rgb(0, 32, 96);
          font-weight: 500;
          font-size: 18px;
        }
      }

      &-arrow {

        &::before,
        &::after {
          background-color: #8c8c8c;
        }
      }
    }
  }
}

.header-popup {
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

.popover-header-layout {
  .ant-popover-inner-content {
    padding: 0px 0px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 6px !important;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .ant-popover .ant-popover-inner-content {
    padding-right: 0px !important;
  }
}